import styled from "styled-components";
import { OutlinedInput } from "@mui/material";

export const FormInput = styled(OutlinedInput)`
  border-radius: 10px;
  border: none !important;
  width: 100%;
  input {
    border-radius: 10px;
    height: 10px;
    font-family: "Avenir Light";
    background: #fff;
    border: none;
  }
  margin-bottom: 20px;
`;
