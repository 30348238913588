import * as React from "react";
import { PageWrapper } from "./PageBackgroundStyle";

interface ModalTypes {
  component?: React.ReactChild;
  color?: string;
  background?: any;
}

function PageBackground({
  component,
  color = "#ffffff",
  background,
}: ModalTypes) {
  return (
    <PageWrapper background={background} style={{ background: color }}>
      {component}
    </PageWrapper>
  );
}

export default PageBackground;
