import * as React from "react";
import { TitleWrapper } from "./TitleStyle";

interface ModalTypes {
  text?: string;
}

function Title({ text }: ModalTypes) {
  return <TitleWrapper>{text}</TitleWrapper>;
}

export default Title;
