import styled from "styled-components";

export const TitleWrapper = styled.div`
  color: #000000;
  font-size: 60px;
  line-height: 81.96px;
  text-align: left;
  @media (max-width: 990px) {
    font-size: 30px;
  }
`;
