import * as React from "react";
import { DotsLeftWrapper } from "./DotsStyle";

function DotsLeft() {
  return (
    <DotsLeftWrapper>
      <img
        className="dots-left"
        src="./dots-left.png"
        style={{ top: 100, left: -150 }}
        alt="dot-left"
      />
    </DotsLeftWrapper>
  );
}

export default DotsLeft;
