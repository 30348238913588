import styled from "styled-components";

export const NetworkWrapper = styled.div`
  image {
    &:hover {
      height: 100px;
      transform: translate(-10px, -10px);
    }
  }
`;
