import styled from "styled-components";

export const ContactUsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .captcha {
    margin-bottom: 20px;
    text-align: -webkit-center;
    @media (max-width: 900px) {
      transform: scale(0.80);
    }
    @media (max-width: 450px) {
      transform: scale(0.80);
    }
  }
`;
