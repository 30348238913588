import * as React from "react";
import DotsRight from "./DostRight";
import DotsLeft from "./DotsLeft";

function DotsBackground() {
  return (
    <>
      <DotsLeft />
      <DotsRight />
    </>
  );
}

export default DotsBackground;
