import styled from "styled-components";
import { Drawer } from "@mui/material";

export const DrawerWrapper = styled(Drawer)`
  margin-top: -20px;
  width: 100vw;
  .MuiListItemText-root {
    text-align: center;
  }
  .MuiListItem-button {
    span {
      font-size: 45px;
      font-family: 'Avenir';
    }
  }   
`;
