import styled from "styled-components";

export const DotsWrapper = styled.div``;

export const DotsLeftWrapper = styled.div`
  .dots-left {
    position: fixed;
    top: 100px;
    left: -150px;
  }
  @media (max-width: 900px) {
    .dots-left {
      transform: scale(0.8);
      top: -150px !important;
      left: -70px !important;
    }
  }
`;

export const DotsRightWrapper = styled.div`
  .dots-right {
    position: fixed;
    right: -120px;
    bottom: -50px;
  }
  @media (max-width: 900px) {
    .dots-right {
      transform: scale(0.5);
      right: -220px !important;
      bottom: -190px !important;
    }
  }
`;
