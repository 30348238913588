import styled from "styled-components";

export const PageWrapper = styled.div<{ background?: any }>`
  background-image: ${(p) =>
    p.background?.image && !p.background?.mobileOnly
      ? `url(${p.background?.image})`
      : "none"} !important;
  background-repeat: no-repeat !important;
  background-position: ${(p) => p.background?.position} !important;
  min-height: 100vh;
  z-index: 1;

  @media (max-width: 900px) {
    width: 100vw;
    min-width: 380px;
    min-height: 100vh;
    background-image: ${(p) =>
      p.background?.image ? `url(${p.background?.image})` : "none"} !important;
    background-repeat: no-repeat !important;
  }
`;
