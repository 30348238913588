import React from 'react';
import { Grid } from "@mui/material";
import ResponsiveAppBar from "../../components/AppBar/AppBar";
import Footer from "../../components/Footer/Footer";
import PageBackground from "../../components/PageBackground/PageBackground";
import Network from "./Network";
import { HomeWrapper, TagLine } from "./styles/HomeStyle";

// const images = [

// ];

function Home() {
  return (
    <PageBackground
      color="#EC5BA5"
      component={
        <>
          <ResponsiveAppBar />
          <HomeWrapper>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <TagLine>
                  <Grid container spacing={2}>
                    <Grid item xs={12} className="main-heading">
                      <img src="./homepage-ul.png" alt="ul" />
                      <h1>Getting things done</h1>
                    </Grid>
                    <Grid item xs={12}>
                      <p>
                        Je vindt bij ons alle expertise op het gebied van (educatieve) digitale content, projectmanagement en IT-development. Let’s meet!
                      </p>
                    </Grid>
                  </Grid>
                </TagLine>
                <br />
              </Grid>
              <Grid item xs={12} md={8}>
                <div className="network-wrapper">
                  <Network />
                </div>
              </Grid>
            </Grid>
            {/* <img src="./map.png" className="map" alt="map" /> */}
            <Footer />
          </HomeWrapper>
        </>
      }
    />
  );
}

export default Home;
