import styled from "styled-components";
import { Avatar } from "@mui/material";

export const CardAvatar = styled(Avatar)`
  &.linked-in {
    &:hover {
      cursor: pointer;
      transform: scale(1.1);
    }
  }
`;
