import * as React from "react";

function Restore({ translateX, translateY }) {
  return (
    <g transform="translate(128 128) scale(0.50 0.50)">
      <g
        style={{ stroke: 'none', strokeWidth: 0, strokeDasharray: 'none', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, fill: 'none', fillRule: 'nonzero', opacity: 1 }}
        transform="translate(-175.05 -175.05000000000004) scale(2 2)"
      >
        <path
          d="M 78.761 56.581 c -1.518 -0.669 -3.286 0.021 -3.955 1.535 C 69.602 69.925 57.902 77.555 45 77.555 c -15.098 0 -27.825 -10.331 -31.491 -24.295 l 1.872 1.858 c 1.177 1.165 3.075 1.16 4.243 -0.017 c 1.167 -1.177 1.16 -3.076 -0.016 -4.243 l -8.05 -7.988 c -0.015 -0.015 -0.034 -0.024 -0.049 -0.039 c -0.134 -0.128 -0.278 -0.244 -0.434 -0.345 c -0.056 -0.036 -0.115 -0.062 -0.173 -0.094 c -0.124 -0.069 -0.249 -0.134 -0.382 -0.186 c -0.071 -0.027 -0.144 -0.045 -0.217 -0.067 c -0.124 -0.037 -0.249 -0.07 -0.38 -0.091 c -0.087 -0.014 -0.174 -0.02 -0.262 -0.026 C 9.588 42.016 9.519 42 9.445 42 c -0.051 0 -0.099 0.013 -0.149 0.015 c -0.043 0.002 -0.085 -0.006 -0.128 -0.002 c -0.05 0.005 -0.096 0.023 -0.146 0.03 c -0.124 0.018 -0.244 0.042 -0.362 0.074 c -0.082 0.022 -0.162 0.046 -0.241 0.075 c -0.12 0.044 -0.234 0.097 -0.346 0.155 c -0.072 0.037 -0.143 0.072 -0.212 0.115 c -0.112 0.07 -0.215 0.151 -0.316 0.234 c -0.058 0.048 -0.118 0.091 -0.173 0.143 c -0.118 0.113 -0.222 0.239 -0.321 0.37 c -0.023 0.031 -0.053 0.055 -0.076 0.087 l -0.019 0.027 c 0 0 0 0 0 0 l -6.426 9.304 c -0.941 1.363 -0.6 3.232 0.764 4.174 c 0.52 0.359 1.114 0.531 1.702 0.531 c 0.952 0 1.889 -0.452 2.471 -1.295 l 1.887 -2.732 c 3.81 17.279 19.237 30.25 37.644 30.25 c 15.279 0 29.134 -9.035 35.296 -23.019 C 80.964 59.02 80.276 57.249 78.761 56.581 z"
          style={{ stroke: 'none', strokeWidth: 1, strokeDasharray: 'none', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, fill: 'rgb(255,255,255)', fillRule: 'nonzero', opacity: 1 }}
          transform=" matrix(1 0 0 1 0 0) "
          strokeLinecap="round"
        />
        <path
          d="M 88.705 33.2 c -1.363 -0.941 -3.232 -0.6 -4.174 0.763 l -1.887 2.732 C 78.835 19.416 63.408 6.445 45 6.445 c -15.278 0 -29.133 9.036 -35.296 23.019 c -0.668 1.516 0.019 3.287 1.535 3.955 c 1.517 0.668 3.287 -0.019 3.955 -1.535 C 20.399 20.075 32.099 12.445 45 12.445 c 15.098 0 27.825 10.332 31.49 24.296 l -1.872 -1.858 c -1.176 -1.167 -3.075 -1.16 -4.243 0.016 c -1.167 1.176 -1.159 3.076 0.017 4.243 l 8.05 7.988 c 0.043 0.043 0.095 0.074 0.141 0.115 c 0.099 0.087 0.197 0.176 0.308 0.25 c 0.06 0.04 0.127 0.067 0.19 0.103 c 0.107 0.061 0.211 0.125 0.325 0.172 c 0.075 0.031 0.155 0.047 0.232 0.072 c 0.108 0.035 0.214 0.076 0.327 0.099 c 0.193 0.039 0.391 0.06 0.591 0.06 c 0.092 0 0.185 -0.004 0.276 -0.013 c 0.05 -0.005 0.096 -0.023 0.145 -0.03 c 0.125 -0.018 0.246 -0.042 0.365 -0.075 c 0.081 -0.022 0.16 -0.045 0.238 -0.074 c 0.121 -0.044 0.236 -0.097 0.349 -0.156 c 0.071 -0.037 0.141 -0.071 0.209 -0.113 c 0.113 -0.071 0.217 -0.152 0.319 -0.236 c 0.057 -0.047 0.117 -0.089 0.171 -0.141 c 0.118 -0.113 0.221 -0.238 0.32 -0.368 c 0.024 -0.032 0.054 -0.056 0.077 -0.089 l 6.445 -9.332 C 90.41 36.01 90.068 34.142 88.705 33.2 z"
          style={{ stroke: 'none', strokeWidth: 1, strokeDasharray: 'none', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, fill: 'rgb(255,255,255)', fillRule: 'nonzero', opacity: 1 }}
          transform=" matrix(1 0 0 1 0 0) "
          strokeLinecap="round"
        />
      </g>
    </g>
  );
}

export default Restore;
