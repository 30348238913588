export const skills = [
  "Bureauredactie",
  "Contentmanagement",
  "Copywriting",
  "Design",
  "Invoeren digitale content",
  "IT-development",
  "Proevencontrole",
  "Projectmanagement",
  "Storytelling",
  "Tekst-en videorechten",
  "Testen digitale content",
  "Trafficmanagement",
  "Transcriptie",
  "Webshopmanagement"
];
