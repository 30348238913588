import styled from "styled-components";

export const SkillsWrapper = styled.div`
  min-height: 100vh;
  .circles {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .partners {
    padding: 5px;
    max-width: 90%;
    max-height: 150px;
  }
  .partners-lg {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
    padding: 5px;
    width: 12vh;
  }
  @media (max-width: 1220px) {
    .partners {
      width: 18vh;
    }
    .partners-lg {
      width: 12vh;
    }
  }
  .title-skills {
    padding-left:10vh;
  }
  @media (max-width: 899px) {
    .title-skills {
      padding-left:5vh;
    }
  }
  .content {
    height: 60vh;
    align-content: center;
    align-items: center;

    .sponsor {
      box-shadow: 2px 2px 15px rgba(148, 138, 139, 0.2);
      border-radius: 20px !important;
    }

    .details-wrapper {
      display: flex;
      text-align: center;
      padding-left: 14%;
      .details {
        height: 60vh;
        // max-width: 380px;
        padding-left: 50px;
        padding-right: 50px;
        display: flex;
        place-content: center;
        flex-direction: column;
        p {
          padding-right: 15%;
        }
      }
    }
    .services-wrapper {
      align-self: left;
      .MuiPaper-rounded {
        border-radius: 0;
        background: none;
        border: none;
        color: #fff;
        box-shadow: none;
      }
      .services-card {
        padding-top: 50px;
        display: flex;
        flex-direction: column;
        border: none;
        align-items: flex-start;

        .MuiTypography-root {
          font-family: "Avenir";
          font-size: 20px;
        }
        img {
          padding-top: 50px;
          // min-width: -webkit-fill-available;
          height: 800px;
          max-height: 890px !important;
        }
      }
    }

    @media (max-width: 1574px) {
      .services-wrapper {
        transform: scale(0.9);
      }
    }
    @media (max-width: 1563px) {
      .services-wrapper {
        transform: scale(0.85);
        padding-right: 0;
        margin-right: 0;
        margin-left: -10px;
      }
    }
    @media (max-width: 1480px) {
      .services-wrapper {
        transform: translate(-15%, 0) scale(0.85);
        padding-right: 0;
        margin-right: 0;
        margin-left: -10px;
      }
      .details-wrapper {
        padding-left: 1%;
      }
    }
    @media (max-width: 899px) {
      .services-wrapper {
        display: none;
      }
      .title-skills {
        padding-left:5vh;
      }
    }
  //   @media (max-width: 1480px) {
  //     .details-wrapper {
  //       padding-left: 8%;
  //     }
  //     .services-wrapper {
  //       .MuiPaper-rounded {
  //         width: 500px;
  //         height: 500px;
  //       }
  //       .services-card {
  //         .MuiTypography-root {
  //           font-size: 16px;
  //         }
  //       }
  //     }
  //   }
  //   @media (max-width: 1266px) {
  //     .details-wrapper {
  //       padding-left: 4%;
  //       .details {
  //         div:first-child {
  //           font-size: 55px;
  //         }
  //         p {
  //           font-size: 16px;
  //         }
  //       }
  //     }
  //     .services-wrapper {
  //       .MuiPaper-rounded {
  //         width: 450px;
  //         height: 450px;
  //       }
  //       .services-card {
  //         .MuiTypography-root {
  //           font-size: 16px;
  //         }
  //       }
  //     }
  //   }
  // }
  // @media (max-width: 899px) {
  //   .content {
  //     text-align: -webkit-center;
  //     .details-wrapper {
  //       padding-left: 0;
  //       .details {
  //         align-items: center;
  //         height: inherit;
  //         width: 100%;
  //         p {
  //           padding-right: 0;
  //           text-align: center;
  //         }
  //         margin-bottom: 40px;
  //       }
  //     }
  //     .services-wrapper {
  //       .MuiPaper-rounded {
  //         width: 450px;
  //         height: 450px;
  //       }
  //       .services-card {
  //         .MuiTypography-root {
  //           font-size: 16px;
  //         }
  //       }
  //       padding-bottom: 20px;
  //     }
  //   }
  // }
  // @media (max-width: 780px) {
  //   .content {
  //     .details-wrapper {
  //       .details {
  //         div:first-child {
  //           font-size: 40px;
  //           line-height: 50px;
  //         }
  //         p {
  //           font-size: 14px;
  //         }
  //       }
  //     }
  //     .services-wrapper {
  //       .MuiPaper-rounded {
  //         width: 400px;
  //         height: 400px;
  //       }
  //       .services-card {
  //         .MuiTypography-root {
  //           font-size: 14px;
  //         }
  //       }
  //     }
  //   }
  // }
  // @media (max-width: 500px) {
  //   .content {
  //     .services-wrapper {
  //       .MuiPaper-rounded {
  //         width: 350px;
  //         height: 350px;
  //       }
  //       .services-card {
  //         padding: 16% 10px 0 10px;
  //         .MuiTypography-root {
  //           font-size: 14px;
  //         }
  //       }
  //     }
  //   }
  // }
  // @media (max-width: 400px) {
  //   .content {
  //     .details-wrapper {
  //       .details {
  //         div:first-child {
  //           font-size: 30px;
  //           line-height: 50px;
  //         }
  //         p {
  //           font-size: 12px;
  //         }
  //       }
  //     }
  //     .services-wrapper {
  //       padding-bottom: 10%;
  //     }
  //   }
  // }
  // @media (max-height: 810px) {
  //   .content {
  //     padding-top: 10%;
  //   }
  // }
  // @media (max-height: 750px) {
  //   .content {
  //     padding-top: 0;
  //     height: inherit;
  //     .details-wrapper {
  //       .details {
  //         padding-top: 0;
  //       }
  //     }
  //     .services-wrapper {
  //       height: inherit;
  //     }
  //   }
  // }
`;

export const DescriptionWrapper = styled.p`
  font-family: "Avenir Light";
  font-size: 15px;
  line-height: 28px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: align-left;
  margin-top: 10px;
`;
