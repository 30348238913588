import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBarWrapper, LogoWrapper } from "./AppBarStyle";
import { MOBILESX, WEBSX } from "../../sx/common";
import { useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, List, ListItem, ListItemText } from "@mui/material";
import { CloseWrapper } from "../Close/CloseStyle";
import { DrawerWrapper } from "../Drawer/DrawerStyle";

interface PropsType {
  isLight: boolean;
  noBg?: boolean;
}

const ResponsiveAppBar = ({ isLight = false, noBg = false }: PropsType) => {
  // const pages = ["home", "about", "skills", "team", "contact"];
  const pages = ["home", "about", "team", "skills", "contact"];
  const [state, setState] = React.useState(false);

  const history = useNavigate();
  const location = useLocation();

  const handleCloseNavMenu = (page) => {
    history(`/${page}`);
  };

  const list = () => (
    <Box
      sx={{ minWidth: "300", width: "100vw" }}
      role="presentation"
      onClick={() => setState(!state)}
      onKeyDown={() => setState(!state)}
    >
      <LogoWrapper
        onClick={(e) => {
          e.stopPropagation();
          history("/home");
        }}
      >
        <Box
          style={{
            width: 200,
            height: 200,
            background: !noBg && "#fff",
          }}
        >
          <img src="./logo.png" height={33} alt="logo" />
        </Box>
      </LogoWrapper>
      <CloseWrapper>
        <CloseIcon />
      </CloseWrapper>
      <List className="mobile-list">
        {pages.map((text, index) => (
          <ListItem button key={index} onClick={() => history(`/${text}`)}>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <AppBarWrapper>
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box
              sx={{
                flexGrow: 1,
                display: { ...WEBSX, md: "contents" },
              }}
            >
              <LogoWrapper
                onClick={(e) => {
                  e.stopPropagation();
                  history("/home");
                }}
              >
                <Box
                  style={{
                    width: 253,
                    height: 253,
                    background: !noBg && "#fff",
                  }}
                >
                  <img src="./logo.png" height={45} alt="logo" />
                </Box>
              </LogoWrapper>
            </Box>
            <Box
              className="buttons-wrapper"
              sx={{ flexGrow: 1, display: WEBSX }}
            >
              {pages.map((page) => (
                <Button
                  key={page}
                  className={`appbar-button ${isLight && "light"} ${
                    location.pathname === `/${page}` && "active"
                  }`}
                  onClick={() => handleCloseNavMenu(page)}
                  sx={{ my: 2, color: "white", display: "block" }}
                >
                  {page}
                </Button>
              ))}
            </Box>
            <Box sx={{ display: MOBILESX, width: "90vw" }} role="presentation">
              <LogoWrapper
                onClick={(e) => {
                  e.stopPropagation();
                  history("/home");
                }}
              >
                <Box
                  style={{
                    width: 200,
                    height: 200,
                    background: !noBg && "#fff",
                  }}
                >
                  <img src="./logo.png" height={33} alt="logo" />
                </Box>
              </LogoWrapper>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                className="menu-appbar"
                onClick={() => setState(true)}
                color="secondary"
              >
                <MenuIcon />
              </IconButton>
              <DrawerWrapper
                anchor="right"
                open={state}
                onClose={() => setState(false)}
              >
                {list()}
              </DrawerWrapper>
            </Box>
            <Box sx={{ width: 253, display: WEBSX }} />
          </Toolbar>
        </Container>
      </AppBar>
    </AppBarWrapper>
  );
};

export default ResponsiveAppBar;
