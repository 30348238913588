import * as React from "react";
import ClickIcon from "./ClickIcon";
import Restore from './Restore';
import { useState } from 'react';

interface CircleType {
  className?: string;
  gClassName?: string;
  r?: string;
  cx?: string;
  cy?: string;
  fill?: string;
  onClick?: any;
  fontSize: string;
  fontFamily?: string;
  textAnchor?: string;
  fillText?: string;
  text?: string;
  textParts?: any;
  pop?: string;
  dy?: string;
}

function Circle({
  className,
  r,
  cx,
  cy,
  text,
  textParts,
  onClick,
  gClassName,
  fill = "#ffffff",
  pop,
  fontSize = "21px",
  fontFamily = "Avenir Light",
  textAnchor = "middle",
  fillText = "#ffffff",
  dy = ".3em",
}: CircleType) {
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  function getText() {
    if (textParts) {
      return (
        <text
          x="50%"
          y="50%"
          text-anchor={textAnchor}
          fill={fillText}
          fontFamily={fontFamily}
          fontSize={fontSize}
        >
          {textParts.map((tp) => (
            <tspan x={cx} y={cy} dy={tp?.dy}>
              {tp?.name}
            </tspan>
          ))}
        </text>
      );
    } else {
      return pop ? (
        pop === "2nd" ? (
          <Restore translateX={cx} translateY={cy} />
        ) : (
          <ClickIcon translateX={cx} translateY={cy} />
        )
      ) : (
        <>
        
        
        <text
          x={cx}
          y={isHovered ? Number(cy) - 10 : cy}
          text-anchor={textAnchor}
          fill={fillText}
          fontFamily={fontFamily}
          fontSize={fontSize}
          dy={dy}
        >
          {text}
        </text>
      {isHovered && text && (
        <text
          fontFamily={fontFamily}
          fontSize={fontSize}
          fill="white"
          x={cx}
          y={Number(cy) + 30}
          dy={dy}
          textAnchor="middle"
          alignmentBaseline="auto"
          style={{ textDecoration: 'underline' }}
        >
          lees meer
        </text>
      )}</>
      );
    }
  }
  return (
    <g className={`circle-wrapper ${gClassName}`} 
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave} onClick={onClick}>
      <circle className={className} r={r} cx={cx} cy={cy} fill={fill}></circle>
      {getText()}
    </g>
  );
}

export default Circle;
