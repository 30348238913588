import React, { useEffect, useRef } from "react";
import { Alert, Box, CircularProgress, Snackbar } from "@mui/material";
import emailjs from "@emailjs/browser";
// import ReCAPTCHA from "react-recaptcha";
import Reaptcha from "reaptcha";
import ResponsiveAppBar from "../../components/AppBar/AppBar";
import BackgroundLogo from "../../components/constants/BackgroundLogo/BackgroundLogo";
import Footer from "../../components/Footer/Footer";
import {
  FormButton,
  FormButtonWrapper,
} from "../../components/form/FormButton/FormButtonStyle";
import { FormInput } from "../../components/form/FormInput/FormInputStyle";
import { FormTextArea } from "../../components/form/FormTextArea/FormTextAreaStyle";
import { Label } from "../../components/form/LabelStyle";
import PageBackground from "../../components/PageBackground/PageBackground";
import { WEBSX } from "../../sx/common";
import { ContactUsWrapper } from "./ContactUsStyle";
import { FormContainerStyle } from "./FormContainerStyle";
import { SUCCCESS_EMAIL } from "../../constants/contactus";

function ContactUs() {
  const form = useRef();
  // const captcha = useRef();
  const [captcha, setCaptcha] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [text, setText] = React.useState(null);
  const [isVerified, toggleIsVerified] = React.useState(false);
  const [recaptchaLoad, toggleRecaptchaLoad] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {}, [isVerified, recaptchaLoad]);

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);
    if (recaptchaLoad && isVerified) {
      emailjs
        .sendForm(
          process.env.REACT_APP_SMTP_SERVICE_ID,
          process.env.REACT_APP_SMTP_TEMPLATE_ID,
          form.current,
          process.env.REACT_APP_SMTP_PUBLIC_KEY
        )
        .then(
          () => {
            setOpen(true);
            setText(SUCCCESS_EMAIL);
            e.target.reset();
            setLoading(false);
            captcha.reset();
            toggleIsVerified(false);
          },
          (error) => {
            setOpen(true);
            setText(error.text);
            setLoading(false);
          }
        );
    } else {
      alert("Please check the Captcha before sending your message");
      setLoading(false);
    }
  };

  const recaptchaLoaded = () => {
    toggleRecaptchaLoad(true);
  };

  const verifiedRecaptcha = (response) => {
    if (response) {
      toggleIsVerified(true);
    }
  };

  return (
    <PageBackground
      color="#F4EBEC"
      background={{
        image: "./side-logo.png",
        mobileOnly: true,
        position: "top right",
      }}
      component={
        <div>
          <ResponsiveAppBar isLight={true} />
          <ContactUsWrapper>
            <Snackbar
              open={open}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              autoHideDuration={5000}
              onClose={handleClose}
            >
              <Alert
                onClose={handleClose}
                severity={text === SUCCCESS_EMAIL ? "success" : "error"}
                sx={{ width: "100%" }}
              >
                {text}
              </Alert>
            </Snackbar>
            <FormContainerStyle ref={form} onSubmit={sendEmail}>
              <Label>Naam</Label>
              <FormInput name="name" required={true} placeholder="Jouw naam" />
              <Label>E-mailadres</Label>
              <FormInput
                name="reply_to"
                required={true}
                placeholder="you@company.com"
              />
              <Label>Telefoonnummer</Label>
              <FormInput
                name="number"
                required={true}
                placeholder="+31 6 123456789"
              />
              <Label>Bericht</Label>
              <FormTextArea
                multiline
                name="message"
                required={true}
                rows={6}
                placeholder="Laat ons weten waarmee we je kunnen helpen!"
              />
              <Reaptcha
                // ref={captcha}
                ref={e => (setCaptcha(e))}
                className="captcha"
                sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                onLoad={recaptchaLoaded}
                onVerify={verifiedRecaptcha}
              />
              <FormButtonWrapper>
                {loading ? (
                  <CircularProgress color="secondary" />
                ) : (
                  <FormButton
                    loading={loading ?? undefined}
                    type="submit"
                    className="block"
                    variant="contained"
                  >
                    Verzenden
                  </FormButton>
                )}
              </FormButtonWrapper>
            </FormContainerStyle>
            <Box sx={{ display: WEBSX }}>
              <BackgroundLogo />
            </Box>
            <Footer color="#00999D" />
          </ContactUsWrapper>
        </div>
      }
    />
  );
}

export default ContactUs;
