import styled from "styled-components";
import { Button } from "@mui/material";

export const FormButtonWrapper = styled.div`
  text-align: center;
  .block {
    width: 100%;
  }
  .MuiButton-contained {
    color: #ffffff;
    background: #000000;
    border-radius: 20px;
  }
`;
export const FormButton = styled(Button)`
  border-radius: 50px;
  min-width: 174px;
  text-transform: initial;
`;
