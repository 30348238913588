import React from "react";
import { Routes as Switch, Route } from "react-router-dom";
import About from "./pages/About/About";
import ContactUs from "./pages/ContactUs/ContactUs";
import Home from "./pages/Home/Home";
import Skills from "./pages/Skills/Skills";
import Team from "./pages/Team/Team";
import PrivacyPolicyPage from "./pages/PrivacyPolicy/PrivacyPolicy";

const Routes = () => {
  return (
    <Switch>
      <Route path="/" element={<Home />} />
      <Route path="/contact" element={<ContactUs />} />
      <Route path="/home" element={<Home />} />
      <Route path="/team" element={<Team />} />
      <Route path="/skills" element={<Skills />} />
      <Route path="/about" element={<About />} />
      <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
    </Switch>
  );
};

export default Routes;
