import * as React from "react";
import { DotsRightWrapper } from "./DotsStyle";

function DotsRight() {
  return (
    <DotsRightWrapper>
      <img
        className="dots-right"
        src="./dots-right.png"
        style={{ right: -120, bottom: -50 }}
        alt="dot-right"
      />
    </DotsRightWrapper>
  );
}

export default DotsRight;
