import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Card, Grid, Typography } from '@mui/material';
import { DescriptionWrapper, SkillsWrapper } from './SkillsStyle';
import ResponsiveAppBar from '../../components/AppBar/AppBar';
import Footer from '../../components/Footer/Footer';
import {
  FormButton,
  FormButtonWrapper,
} from '../../components/form/FormButton/FormButtonStyle';
import PageBackground from '../../components/PageBackground/PageBackground';
import Title from '../../components/typography/Title/Title';
import { MOBILESX } from '../../sx/common';
import { skills } from '../../constants/skills';
// import { skills } from "../../constants/skills";
// import { SubHeader } from '../../components/typography/SubHeader/SubHeaderStyle';
// import SkillBlocks from './SkillBlocks';
// import { SubHeader } from '../../components/typography/SubHeader/SubHeaderStyle';

//1280 x 600

const partnerData = [
  {
    image: './partners/malmberg.png',
    description:
      'Al vijftien jaar werken wij mee aan verschillende methoden van Malmberg, voor zowel het voortgezet onderwijs als het MBO. Ook regelen we tekstrechten en helpen we bij diverse andere werkzaamheden die bij het uitgeefproces komen kijken.',
  },
  {
    image: './partners/Zwijsen.jpg',
    description:
      'Wij ondersteunen Zwijsen bij  toonaangevende lesmethoden in het basisonderwijs, zoals Estafette, Veilig Leren Lezen, Stapel Op en Semsom.',
  },
  {
    image: './partners/van in.png',
    description:
      'VAN IN is de grootste educatieve uitgever van leermiddelen in België. Wij werken mee aan de digitalisering van diverse VO- en basisonderwijsmethoden.',
  },
  {
    image: './partners/jongleren.png',
    description:
      'Door het bouwen en onderhouden van een eigen app ondersteunen wij JongLeren in het optimaliseren van hun stagebemiddelingsproces.',
  },
  {
    image: './partners/rntall.jpg',
    description:
      'Deze start-up uit België gaat de verhuurmarkt van producten vereenvoudigen door huurders en verhuurders direct in contact te brengen via een door ons gebouwde app.',
  },
  {
    image: './partners/biocoherence.png',
    size: '70%',
    description:
      'Sinds 2019 werken we aan een geavanceerd platform dat de alternatieve en preventieve geneeskunde naar een hoger plan brengt. Het doel is om succesvolle behandelingen meetbaar te maken.',
  },
  {
    image: './partners/prijsvrij.png',
    description:
      'We ondersteunen één van Nederlands grootste reisorganisaties met back- en frontoffice werkzaamheden. Onze flexibiliteit leent zich hier erg goed voor.',
  },
];

function Skills() {
  const history = useNavigate();
  return (
    <PageBackground
      color='#F4EBEC'
      component={
        <SkillsWrapper>
          <ResponsiveAppBar isLight={true} />
          <Grid container className='content'>
            <Grid xs={12} md={6} lg={6} className='details-wrapper'>
              <div className='details'>
                <Title text='Wat we doen' />
                <DescriptionWrapper>
                  Staat waar jij naar op zoek bent er niet tussen? Vraag het ons
                  gerust. We leren graag! 
                </DescriptionWrapper>
                <FormButtonWrapper
                  style={{ textAlign: 'left' }}
                  onClick={() => history('/contact')}
                >
                  <FormButton variant='contained'>CONTACT</FormButton>
                </FormButtonWrapper>
              </div>
            </Grid>
            <Box sx={{ display: MOBILESX, width: '95vw' }}>
              <Grid xs={12} md={6} lg={6}>
                <div
                  style={{
                    textAlign: 'center',
                    marginTop: -100,
                    background: '#90174c',
                    color: '#fff',
                    borderRadius: 200,
                    padding: 20,
                  }}
                  className='services-card'
                >
                  {skills.map((s) => (
                    <Typography>{s}</Typography>
                  ))}
                </div>
              </Grid>
            </Box>
            <Grid xs={12} md={6} lg={6} className='services-wrapper'>
              <div className='services-card'>
                {/* {skills.map((s) => (
                    <Typography>{s}</Typography>
                  ))} */}
                <img src='./Skills copy.png' alt='img' />
                {/* <SkillBlocks /> */}
              </div>
            </Grid>
          </Grid>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <Grid container style={{ height: 'inherit' }} className='content'>
            <Grid xs={12} md={12} lg={12} className='details-wrapper'>
              <div style={{ height: 'inherit' }} className='details'>
                <Title text='Voor wie we dit doen' />
              </div>
            </Grid>
            <Grid xs={12} md={12} lg={12}>
              <Grid style={{ padding: 20 }} container>
                {partnerData.map((partner, index) => (
                  <Grid
                    container
                    key={index}
                    xs={12}
                    md={4}
                    sm={6}
                    style={{ padding: 20 }}
                  >
                    <Card
                      className='sponsor'
                      style={{
                        padding: 10,
                        paddingTop: 20,
                        minHeight: 350,
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          minHeight: 150,
                          alignItems: 'center',
                          justifyContent: 'center',
                          textAlign: 'center',
                        }}
                      >
                        <img
                          src={partner.image}
                          style={{
                            maxWidth: partner?.size ? partner.size : '90%',
                          }}
                          className='partners'
                          alt='partners'
                        />
                      </div>
                      <DescriptionWrapper>
                        {partner.description}
                      </DescriptionWrapper>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
          {/* <SubHeader>Partners we’re helping to grow</SubHeader> */}
          <Footer color='#00999D' />
        </SkillsWrapper>
      }
    />
  );
}

export default Skills;
