import styled from "styled-components";
import { Card, Stack } from "@mui/material";

export const TeamWrapper = styled.div<{ page?: string }>`
  min-height: 100vh;

  .cards-wrapper {
    .MuiGrid-grid-xs-2 {
      max-width: 12%;
      flex-basis: 12%;
    }
    .MuiGrid-grid-xs-8 {
      max-width: 76%;
      flex-basis: 76%;
    }
  }
  .header {
    align-items: center;
    padding-top: 1%;
    margin-bottom: 20px;
    .title {
      text-align-last: right;
    }
    .description {
      text-align-last: left;
      max-width: 582px;
      p {
        color: #7e7e7e;
        font-size: 19px;
      }
    }
    @media (max-width: 1199px) {
      justify-content: center;

      .title {
        text-align-last: center;
      }
      .description {
        text-align-last: center;
      }
    }
    @media (max-width: 900px) {
      padding: 0 20px;
      justify-content: left;
      .title {
        text-align-last: left;
        font-size: 40px;
      }
      .description {
        p {
          font-size: 18px;
        }
      }
    }
  }

  .react-multi-carousel-list {
    padding-bottom: 2em;

    .react-multiple-carousel__arrow {
      position: fixed;
      top: 50%;
    }
    @media (max-width: 900px) {
      .react-multiple-carousel__arrow {
        &--right {
          margin-right: -10px;
        }
        &--left {
          margin-left: -10px;
        }
      }
    }
    .react-multi-carousel-dot-list {
      .react-multi-carousel-dot--active {
        button {
          background: #000;
        }
      }
      li {
        padding-right: 10px;
        button {
          border-radius: 7.5px;
          border: none;
          width: 42.34px;
          height: 6px;
          background: #0000004f;
        }
      }
    }

    @media (max-width: 900px) {
      padding-bottom: 3em;
      .MuiPaper-elevation1 {
        max-width: inherit;
      }
      .react-multi-carousel-dot-list {
        li {
          button {
            min-width: 10px;
            max-width: 30px;
            width: auto;
          }
        }
        padding-bottom: 10px;
      }
    }
  }
`;

export const CardWrapper = styled(Card)`
  background: #ffffff;
  box-shadow: 2px 2px 15px rgba(148, 138, 139, 0.2);
  border-radius: 20px !important;
  height: 600px;
  margin-bottom: 20px;
  .MuiCardContent-root {
    padding: 30px 30px;
  }
  .name {
    text-align: left;
    font-size: 22px;
    color: #000;
    padding-top: 10px;
    font-family: "Avenir Light";
  }
  .position {
    text-align: left;
    color: #7e7e7e;
    size: 16px;
    font-family: "Avenir Light";
    margin-top: -10px;
    padding-bottom: 10px;
  }
  .email {
    text-align: left;
    color: #7e7e7e;
    font-size: 14px;
    font-family: "Avenir Light";
    margin-top: -10px;
    padding-bottom: 10px;
  }
  @media (max-width: 900px) {
    height: 450px;
    .MuiCardContent-root {
      padding: 36px;
    }
    .name {
      text-align: center;
    }
    .position {
      text-align: center;
    }
    .avatar {
      justify-content: center;
    }
  }
  .details {
    text-align: left;
    color: #7e7e7e;
    font-size: 12.5px;
    font-family: "Avenir Light";
    @media (max-width: 900px) {
      font-size: 12px;
    }
  }
  @media (max-width: 900px) {
  min-height: 45  0px;
    .MuiCardContent-root {
      padding: 20px;
    }
    .details {
      font-size: 11px;
    }
  }
`;

export const StackWrapper = styled(Stack)`
  div:nth-child(1) {
    margin-left: 20px;
    margin-top: 10px;
  }
  @media (max-width: 900px) {
    div:nth-child(1) {
      margin-left: 10px;
      margin-top: 5px;
      height: 120px;
      width: 120px;
    }
  }
  div:nth-child(2) {
    margin-left: -30px;
  }
`;
