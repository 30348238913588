import styled from "styled-components";

export const FooterWrapper = styled.div<{ position?: any }>`
  position: ${(p) => (p.position ? `${p.position}` : "initial")};
  font-size: 14px;
  bottom: 0;
  width: 98%;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 10px;
  font-family: "Avenir Light";

  @media (min-width: 700px) {
    text-align: center;
  }

  @media (max-width: 900px) {
    position: ${(p) => (p.position ? `${p.position}` : "initial")};
    bottom: 0;
    font-size: 10px;
    text-align: left;
    padding-bottom: 10px;
    .MuiGrid-item {
      padding-left: 5px;
    }
  }
  @media (max-width: 550px) {
    position: ${(p) => (p.position ? `${p.position}` : "inherit")};
    bottom: 0;
    font-size: 10px;
    text-align: left;
    padding-bottom: 10px;
    .MuiGrid-item {
      padding-left: 5px;
    }
  }
`;
