import styled from "styled-components";

export const FormContainerStyle = styled.form`
  position: relative;
  z-index: 777;
  width: 60%;
  max-width: 569px;
  padding-bottom: 40px;

  @media (max-width: 900px) {
    width: 80%;
  }
`;
