import React from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import privacyPolicyData from "./privacy_policy.json";
import PageBackground from "../../components/PageBackground/PageBackground";
import ResponsiveAppBar from "../../components/AppBar/AppBar";
import Footer from "../../components/Footer/Footer";
import { PrivacyPolicyWrapper } from "./PrivacyPolicyStyle";

// interface PrivacyPolicySection {
//   heading: string;
//   paragraphs?: string[];
//   listItems?: { title: string; description: string }[];
//   subSections?: {
//     subHeading: string;
//     paragraphs?: string[];
//     listItems?: { title: string; description: string }[];
//   }[];
// }

interface PrivacyPolicyProps {
  // title: string;
  // lastUpdated: string;
  // sections: PrivacyPolicySection[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  section: {
    marginBottom: theme.spacing(4),
  },
  subSection: {
    marginTop: theme.spacing(1),
  },
  paragraph: {
    marginTop: theme.spacing(0.1),
  },
}));

const PrivacyPolicyPage: React.FC<PrivacyPolicyProps> = () => {
  const classes = useStyles();
  const { title, lastUpdated, sections } = privacyPolicyData;

  return (
    <PageBackground
      color="#F4EBEC"
      background={{
        image: "./side-logo.png",
        mobileOnly: false,
        position: "top right",
      }}
      component={
        <div>
          <ResponsiveAppBar isLight={true} />
          <PrivacyPolicyWrapper>
            <Typography variant="h4" gutterBottom>
              {title}
            </Typography>
            <Typography variant="subtitle2" color="textSecondary" gutterBottom>
              Last Updated: {lastUpdated}
            </Typography>
            <br/>
            {sections.map((section, index) => (
              <div key={index} className={classes.section}>
                {section.heading && (
                  <Typography variant="h6" gutterBottom>
                    {section.heading}
                  </Typography>
                )}
                {section.paragraphs &&
                  section.paragraphs.map((paragraph, pIndex) => (
                    <div key={pIndex} className={classes.paragraph}>
                      <Typography variant="body2" paragraph>
                        {paragraph}
                      </Typography>
                    </div>
                  ))}
                {section.listItems && (
                  <List>
                    {section.listItems.map((item, lIndex) => (
                      <ListItem key={lIndex}>
                        <ListItemText
                          primary={item.title}
                          secondary={item.description}
                        />
                      </ListItem>
                    ))}
                  </List>
                )}
                {section.subSections &&
                  section.subSections.map((subSection, sIndex) => (
                    <div key={sIndex} className={classes.subSection}>
                      <Typography variant="subtitle1" gutterBottom>
                        {subSection.subHeading}
                      </Typography>
                      {subSection.paragraphs &&
                        subSection.paragraphs.map((paragraph, spIndex) => (
                          <Typography key={spIndex} variant="body2" paragraph>
                            {paragraph}
                          </Typography>
                        ))}
                      {subSection.listItems && (
                        <List>
                          {subSection.listItems.map((item, slIndex) => (
                            <ListItem key={slIndex}>
                              <ListItemText
                                primary={item.title}
                                secondary={item.description}
                              />
                            </ListItem>
                          ))}
                        </List>
                      )}
                    </div>
                  ))}
              </div>
            ))}
            <Footer color="#00999D" />
          </PrivacyPolicyWrapper>
        </div>
      }
    />
  );
};

export default PrivacyPolicyPage;
