export const circles = [
  {
    cx: "669.503",
    cy: "246.978",
    fill: "#F49FCA",
    text: "",
  },
  {
    cx: "546.339",
    cy: "126.908",
    fill: "#F4DAE8",
    text: "Kwaliteit",
    description: [
      {
        style: null,
        content:
          "De kwaliteit die we leveren én het vertrouwen van onze klanten geven ons ons bestaansrecht. Pink Raspberry is een platte, kleine organisatie met korte lijnen. Ieder project heeft een eigen specialist en alle projecten worden op hetzelfde, hoge niveau uitgevoerd.",
      },
      {
        style: { fontStyle: "italic", fontWeight: 600 },
        className: 'bold',
        content:
          "‘’ Werkzaamheden kun je zorgeloos aan Pink Raspberry overlaten. Het werk wordt deskundig en goed uitgevoerd, je kan vertrouwen op de gemaakte afspraken en de kwaliteit is constant hoog.’’",
      },
      {
        style: null,
        content:
          " – Saskia Bruil Projecten",
      },
    ],
  },
  {
    cx: "405.557",
    cy: "246.977",
    fill: "#F5C8E0",
    text: "Educatie",
    description: [
      {
        style: null,
        content:
          "Sinds 2008  werken wij aan verschillende projecten voor educatieve uitgeverijen. Denk hierbij aan contentmanagement en projectmanagement. Maar ook proevencontrole, bureauredactie, invoer en testen. Wij dragen bij aan het onderwijs op allerlei niveaus in Nederland en België door onze samenwerking met onder andere Sanoma Learning en uitgeverij Zwijsen.",
      },
      {
        style: { fontStyle: "italic", fontWeight: 600 },
        className: 'bold',
        content:
          "“Pink Raspberry heeft een heldere communicatie, reageert snel op vragen en heeft een hoge mate van meedenken. Hierdoor voel je je als klant gezien!“",
      },
      {
        style: null,
        content:
          " – Christianne Vogels (Malmberg)",
      },
    ],
  },
  {
    cx: "685.055",
    cy: "568.134",
    fill: "#C52274",
    text: "",
  },
  {
    cx: "700.584",
    cy: "421.945",
    fill: "#D23484",
    text: "Ervaring",
    description: [
      {
        style: null,
        content:
          "Pink Raspberry heeft meer dan vijftien jaar ervaring in de uitgeefbranche. We hebben aan een half woord genoeg en maken nieuwe projecten en systemen ons snel eigen. We leveren een hoge kwaliteit en denken graag mee.",
      },
      {
        style: { fontStyle: "italic", fontWeight: 600 },
        className: 'bold',
        content:
          "‘’Pink Raspberry heeft veel ervaring en kennis en hebben daardoor aan een half woord genoeg. Ze hebben hart voor de projecten en een goed oog voor detail. Met Pink Raspberry is je project in deskundige handen!’’",
      },
      {
        style: null,
        content:
          " – Roy van Boxtel Uitgeefondersteuning",
      },
    ],
  },
  {
    cx: "556.122",
    cy: "341.365",
    fill: "#F18DC0",
    text: "Best (wo)man for the job",
    description: [
      {
        style: null,
        content:
          "We werken met ‘the best (wo)man for the job’, en dit is niet alleen ‘the best one on paper’. Met veel gedrevenheid, toewijding en enthousiasme werken we met onze klanten aan een gezamenlijk doel.",
      },
    ],
  },
  {
    cx: "127.349",
    cy: "410.654",
    fill: "#F4B0D3",
    text: "",
  },
  {
    cx: "625.201",
    cy: "661.092",
    fill: "#99004D",
    text: "",
  },
  {
    cx: "251.676",
    cy: "341.371",
    fill: "#F4BDDA",
    text: "Internationaal",
    description: [
      {
        style: null,
        content:
          "Het team van Pink Raspberry werkt internationaal. Vanuit Nederland tot Australië en vanuit Spanje tot de Filipijnen. Op basis van  wederzijds vertrouwen, goede afspraken en blik op de lange termijn draait Pink Raspberry 24/7 door. Het is een estafettemodel dat tijdwinst oplevert: als de een slaapt, werkt de ander, en andersom!",
      },
    ],
  },
  {
    cx: "203.318",
    cy: "524.034",
    fill: "#EF69AD",
    text: "Divers",
    description: [
      {
        style: null,
        content:
          "Divers met een hoofdletter D. Wij werken vanuit de hele wereld, in verschillende tijdzones en culturen. Leeftijd en achtergrond is niet bepalend, wij kijken naar kwaliteit. Ook heeft iedereen inmiddels zijn of haar eigen specialisaties opgebouwd, zo weten we precies wie we voor welke klus het beste kunnen inzetten.",
      },
    ],
  },
  {
    cx: "455.408",
    cy: "661.091",
    fill: "#B71667",
    text: ''
  },
  {
    cx: "319.57",
    cy: "629.357",
    fill: "#EC5BA5",
    text: "Het nieuwe werken",
    description: [
      {
        style: null,
        content:
          "Pink Raspberry en het nieuwe werken gaan hand in hand samen. Sinds 2018 is onze hele organisatie op deze manier ingericht. Werken waar en wanneer je wilt? Jazeker! Wij zijn ervan overtuigd dat we op deze manier de beste kwaliteit én het meeste plezier uit ons werk halen. ",
      },
    ],
  },
  {
    cx: "546.339",
    cy: "535.325",
    fill: "#E04795",
    text: "Flexibel",
    description: [
      {
        style: null,
        content:
          "Met een vast team en een uitgebreide flexibele schil aan  enthousiaste en ervaren collega’s kunnen wij gemakkelijk op- en afschalen. Handig als er veel werk in een korte periode verricht moet worden. Bij ons geen 9-tot-5-mentaliteit!",
      },
      {
        style: { fontStyle: "italic", fontWeight: 600 },
        className: 'bold',
        content:
          "‘’Altijd kwaliteit, altijd op tijd en met een gulle lach. Op Pink Raspberry kun je bouwen.’’",
      },
      {
        style: null,
        content:
          " – Marieke van Tilborg-Reijken (Malmberg)",
      },
    ],
  },
  {
    cx: "398.433",
    cy: "454.748",
    fill: "#F37BB8",
    text: "Software development",
    description: [
      {
        style: null,
        content:
          "Content is een breed begrip. Om onze klanten zo goed mogelijk te bedienen, hebben we sinds 2019 een in-house ontwikkelteam dat websites ontwerpt en bouwt. Maar ook apps en complexe platformen. Voor het gehele proces van softwareontwikkeling kun je bij ons terecht.",
      },
    ],
  },
];
