import styled from "styled-components";
import { OutlinedInput } from "@mui/material";

export const FormTextArea = styled(OutlinedInput)`
  border: none;
  border-radius: 10px;
  font-family: "Avenir Light";
  background: #fff;
  border: none;
  width: 100%;
  margin-bottom: 20px;
`;
