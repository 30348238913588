import { Grid } from "@mui/material";
import * as React from "react";
import { FooterWrapper } from "./FooterStyle";
import { useNavigate } from "react-router-dom";

interface ModalTypes {
  color?: string;
  fixed?: boolean;
}

function Footer({ color = "#78033E" }: ModalTypes) {
  const history = useNavigate();
  return (
    <FooterWrapper style={{ color: color }}>
      <Grid container>
        <Grid md={3} xs={7} item>
          C. de Calàbria 171, piso 9-5, 08015 Barcelona
        </Grid>
        <Grid
          md={3}
          xs={5}
          item
          style={{ cursor: "pointer" }}
          onClick={() => history(`/privacy-policy`)}
        >
          Privacy Policy
        </Grid>
        <Grid md={3} xs={7} item>
          info@pinkraspberry.nl
        </Grid>
        <Grid md={3} xs={5} item>
          ©2022 Pink Raspberry
        </Grid>
      </Grid>
    </FooterWrapper>
  );
}

export default Footer;
