import * as React from "react";
import { BackgroundLogoWrapper } from "./BackgroundLogoStyle";

function BackgroundLogo() {
  return (
    <BackgroundLogoWrapper>
      <img src="./side-logo.png" className="side-logo" alt="side-logo" />
    </BackgroundLogoWrapper>
  );
}

export default BackgroundLogo;
