export const team = [
  {
    name: "Liselotte",
    position: "Team leader | Project manager",
    description:
      "Toen ik in november 2008 Pink Raspberry oprichtte, gedreven door mijn passie voor organiseren en communicatie, had ik nooit verwacht uiteindelijk hier te staan. Als meewerkend voorvrouw ben ik erg trots op ons bedrijf, onze medewerkers, projecten en klanten.",
    src: "./avatars/Lot.png",
    email: "liselotte.arts@pinkraspberry.nl",
    linkedIn: "https://www.linkedin.com/in/liselottearts",
  },
  {
    name: "Melvin",
    position: "Business developer",
    description:
      "Sinds 2018 ben ik betrokken bij Pink Raspberry en ik prijs me erg gelukkig dat ik met dit team mag samenwerken. Dit deden we jarenlang vanuit de gekste en leukste plekken ter wereld en op dit moment vanuit het heerlijke Barcelona. Ik houd me vooral bezig met de ontwikkeling van IT en denk graag intern en voor klanten mee over lange termijn-oplossingen.",
    src: "./avatars/Melvin.png",
    email: "melvin.oomes@pinkraspberry.nl",
    linkedIn: "https://www.linkedin.com/in/melvin-oomes-089b0623",
  },
  {
    name: "Lotte",
    position: "Project manager",
    description:
      "Hola! Ik ben Lotte en ik ben projectmanager bij Pink Raspberry. Dit doe ik vanuit het hoofdkantoor in het warme Barcelona. Ik ben verantwoordelijk voor het coördineren van diverse projecten met medewerkers die zich over de hele wereld bevinden. Daarnaast houd ik mij bezig met het verwerven van rechten voor tekst en beeld. ",
    src: "./avatars/Lotte.png",
    email: "lotte.vandijk@pinkraspberry.nl",
    linkedIn: "https://www.linkedin.com/in/lotte-van-dijk-7aa954ba/",
  },
  {
    name: "Britt",
    position: "Project manager",
    description:
      "Sinds 2023 maak ik deel uit van het superleuke team bij Pink Raspberry. Met mijn recent afgeronde studie International Hospitality Management ben ik vol enthousiasme begonnen aan deze nieuwe fase in mijn carrière. Ik ben erg gemotiveerd en kijk altijd naar nieuwe kansen om te leren en te groeien.",
    src: "./avatars/Britt.png",
    email: "britt.sempel@pinkraspberry.nl",
    linkedIn: "https://www.linkedin.com/in/britt-sempel-b24389199/",
  },
  {
    name: "Zoë",
    position: "Project manager",
    description:
      "‘’Ik heb het nog nooit gedaan, dus ik denk dat ik het wel kan’’ – werken als projectmanager in het zonnige Barcelona! Als projectmanager bij Pink Raspberry ben ik verantwoordelijk voor diverse taken, waaronder het in goede banen leiden van verschillende projecten. Ik krijg energie van plannen, structureren, to-do-lijstjes en samenwerken met klanten en collega’s. Met de ervaring die ik bij eerdere werkgevers heb opgedaan, is deze functie er een die mij perfect past en waar ik erg veel energie van krijg!",
    src: "./avatars/Zoë.png",
    email: "zoe.lammers@pinkraspberry.nl",
    linkedIn: "https://www.linkedin.com/in/zoe-lammers/",
  },
  {
    name: "Ruben",
    position: "Technical support",
    description:
      "In 2022 ben ik met veel enthousiasme begonnen bij Pink Raspberry. Oorspronkelijk heb ik mijn wortels in de biologie, maar het was tijd voor een nieuwe uitdaging en daarom werk ik nu met veel plezier in dit gezellige team.",
    src: "./avatars/Ruben.png",
    linkedIn: "https://www.linkedin.com/company/pink-raspberry",
  },
  {
    name: "Yaisa",
    position: "PVC | Uitgangscontrole",
    description:
      "Ooit bij “de” bank begonnen en na vele omzwervingen rond de wereld met verschillende beroepen, kwam ik in 2023 in contact met Pink Raspberry. Een ideale opdrachtgever om te combineren met het geven van yogalessen in Australië en het opleiden van yogaleraren op Bali. Als ware digital nomad houd ik me bezig met het digitaliseren en controleren van educatieve content. Mooi en gevarieerd werk. Wat zijn de schoolboeken leuk vergeleken met dert.. nou ja, een paar jaar terug!",
    src: "./avatars/Yaisa & Coffee.png",
    linkedIn: "https://www.linkedin.com/company/pink-raspberry",
  },
  {
    name: "Yara",
    position: "PVC | Uitgangscontrole",
    description:
      "Ik ben een enthousiaste duizendpoot met een achtergrond als criminoloog en scriptiebegeleider, een passie voor onderwijs en ontwikkeling en een voorliefde voor Spaanse sferen. Deze ‘poten’ komen mooi samen bij Pink Raspberry, omdat ik elke dag weer een andere (roze) pet opzet. Ik houd me bezig met het controleren, redigeren en digitaliseren van verschillende soorten educatieve content.",
    src: "./avatars/Yara.png",
    linkedIn: "https://www.linkedin.com/company/pink-raspberry",
  },
  {
    name: "Julia",
    position: "PVC | Uitgangscontrole",
    description:
      "Van origine ben ik onderwijsdeskundige en opvoedingsondersteuner (VU en UVA) en gedurende mijn hele carrière ben ik eigenlijk altijd verbonden geweest met onderwijs, trainingsprogramma´s, methodologie en welzijn van studenten en werknemers. Zowel in Nederland als Spanje en internationaal. Bij Pink Raspberry ondersteun ik met veel plezier het team vanuit Barcelona met controle, digitalisering en verwerking van de content.",
    src: "./avatars/Julia.png",
    linkedIn: "https://www.linkedin.com/company/pink-raspberry",
  },
  {
    name: "Amina",
    position: "Data processing",
    description:
      "Ik werk vanaf 2019 met veel plezier bij Pink Raspberry. Ik ben geboren in Antwerpen, maar woon nu in Melbourne, Australië. Ik houd mij vooral bezig met het invoeren van diverse vakken in het verschillende contentsystemen. Werken voor Pink Raspberry is superfijn omdat ik zelf indeel wanneer én waar ik werk.",
    src: "./avatars/Amina.png",
    linkedIn: "https://www.linkedin.com/company/pink-raspberry",
  },
  {
    name: "Jim",
    position: "Data processing",
    description:
      "Sinds 2022 werk ik met veel plezier bij Pink Raspberry. Het feit dat ik zelf kan bepalen waar en wanneer ik werk, maakt dat ik mijn werkzaamheden perfect kan combineren met mijn studie Econometrie. Fijne collega’s en een goede werksfeer zorgen ervoor dat ik het werk erg leuk vind!",
    src: "./avatars/Jim.png",
    linkedIn: "https://www.linkedin.com/company/pink-raspberry",
  },
  {
    name: "Matteo",
    position: "Data processing | Testing",
    description:
      "Met veel plezier werk ik bij Pink Raspberry en ik houd me voornamelijk bezig met het invoeren van content in de verschillende content systemen. Nu en dan doe ik dit werk vanuit Nederland, maar ook vaak vanuit de mooiste locaties in het buitenland. Voor mij is dit de ideale baan, werken in een leuk en gezellig team, helpen in het onderwijs en flexibel om te werken waar en wanneer ik wil.",
    src: "./avatars/Matteo.png",
    linkedIn: "https://www.linkedin.com/company/pink-raspberry",
  },
  {
    name: "Dion",
    position: "Data processing | Testing",
    description:
      "Ik werk met veel plezier bij Pink Raspberry. Het leuke aan deze baan vind ik dat ik wekelijks met andere projecten bezig ben. Omdat ik zelf kan bepalen waar, wanneer én hoeveel ik werk is dit voor mij de ideale baan naast mijn studie Bedrijfskunde.",
    src: "./avatars/Dion.png",
    linkedIn: "https://www.linkedin.com/company/pink-raspberry",
  },
  {
    name: "Siem",
    position: "Data processing | Testing",
    description:
      "Werken bij Pink Raspberry past perfect bij mijn tussenjaar, want los van wat ik besluit te gaan doen of waar ik heen zal gaan, ik zal door de flexibiliteit van deze baan altijd kunnen werken. Het team is onwijs verwelkomend en behulpzaam, dus ik werk hier met veel plezier! ",
    src: "./avatars/Siem.png",
    linkedIn: "https://www.linkedin.com/company/pink-raspberry",
  },
  {
    name: "Ties",
    position: "Data processing | Testing",
    description:
      "Ik maak met veel plezier deel uit van het Pink Raspberry team. Ik houd me vooral bezig met het testen van verschillende projecten op allerlei niveaus. Dit werk is voor mij als student ideaal te combineren met mijn studie Rechten aan de Radboud Universiteit in Nijmegen.",
    src: "./avatars/Ties.png",
    linkedIn: "https://www.linkedin.com/company/pink-raspberry",
  },
  {
    name: "Ciara",
    position: "Data processing | Testing",
    src: "./avatars/Ciara.png",
    linkedIn: "https://www.linkedin.com/company/pink-raspberry",
    description:
      "Ik vind werken bij Pink Raspberry echt fantastisch! Aangezien ik op dit moment geen studie volg, kan ik veel uren maken en dit doe ik dan ook met heel veel plezier. Het is een supergezellig en enthousiast team, met collega's die altijd voor je klaarstaan om te helpen. Op dit moment ben ik vooral bezig met (digitaal) invoerwerk, maar soms krijg ik ook andere leuke taken. Ik ben enorm dankbaar voor dit werk en hoop dit nog lang te mogen blijven doen!",
  },
  {
    name: "Kim",
    position: "Tekstrechten | Data processing",
    description:
      "Mabuhay vanuit “The Land of Smiles”! Inmiddels woon ik alweer 15 jaar in de tropische Filipijnen. Met een achtergrond in financiën en horeca, geniet ik nu van de flexibiliteit en variatie van het werk bij Pink Raspberry. Met veel plezier maak ik deel uit van het team, waarin ik erg fijn en gestructureerd “op zijn Nederlands” kan werken, want dat is hier vaak wel een uitdaging, en dit kan combineren met het mooie van de tropen en mijn vrijwilligerswerk.",
    src: "./avatars/Kim.png",
    linkedIn: "https://www.linkedin.com/company/pink-raspberry",
  },
  {
    name: "Cath",
    position: "Software development",
    description:
      "I transform caffeine into code with expertise that spans the entire software development lifecycle. With years of experience not just in full-stack development but also in various facets of the software industry, I bring a deep understanding of both front-end and back-end technologies, as well as the strategic thinking needed to drive projects from conception to completion. Beyond my technical skills, I'm a human who enjoys curating a collection of pink peripherals.",
    src: "./avatars/Cath.png",
    linkedIn: "https://www.linkedin.com/company/pink-raspberry",
  },
];

export const teamHomePage = [
  {
    name: "Liselotte",
    position: "Team leader | Project manager",
    src: "./avatars_small/Lot.png",
    email: "liselotte.arts@pinkraspberry.nl",
    linkedIn: "https://www.linkedin.com/in/liselottearts",
  },
  {
    name: "Melvin",
    position: "Business developer",
    src: "./avatars_small/Melvin.png",
    email: "melvin.oomes@pinkraspberry.nl",
    linkedIn: "https://www.linkedin.com/in/melvin-oomes-089b0623",
  },
  {
    name: "Ciara",
    position: "Data processing | Testing",
    src: "./avatars_small/Ciara.png",
    linkedIn: "https://www.linkedin.com/company/pink-raspberry",
  },
  {
    name: "Yara",
    position: "",
    src: "./avatars_small/Yara.png",
    linkedIn: "https://www.linkedin.com/company/pink-raspberry",
  },
  {
    name: "Julia",
    position: "Expert in educatie",
    src: "./avatars_small/Julia.png",
    linkedIn: "https://www.linkedin.com/company/pink-raspberry",
  },
  {
    name: "Kim",
    position: "Tekstrechten | Data processing",
    description:
      "Mabuhay vanuit “The Land of Smiles”! Inmiddels woon ik alweer 15 jaar in de tropische Filipijnen. Met een achtergrond in financiën en horeca, geniet ik nu van de flexibiliteit en variatie van het werk bij Pink Raspberry. Met veel plezier maak ik deel uit van het team, waarin ik erg fijn en gestructureerd “op zijn Nederlands” kan werken, want dat is hier vaak wel een uitdaging, en dit kan combineren met het mooie van de tropen en mijn vrijwilligerswerk.",
    src: "./avatars_small/Kim.png",
    linkedIn: "https://www.linkedin.com/company/pink-raspberry",
  },
  {
    name: "Amina",
    position: "Data processing / invoer",
    src: "./avatars_small/Amina.png",
    linkedIn: "https://www.linkedin.com/company/pink-raspberry",
  },
  {
    name: "Britt",
    position: "Expert basisonderwijs",
    src: "./avatars_small/Britt.png",
    linkedIn: "linkedin.com/in/andrea-reuvers-223075145",
  },
  {
    name: "Cath",
    position: "Software development",
    src: "./avatars_small/Cath.png",
    linkedIn: "https://www.linkedin.com/company/pink-raspberry",
  },
  {
    name: "Zoë",
    position: "",
    description: "",
    src: "./avatars_small/Zoë.png",
    linkedIn: "",
  },
  {
    name: "Lotte",
    position: "",
    description: "",
    src: "./avatars_small/Lotte.png",
    linkedIn: "",
  },
  {
    name: "Jim",
    position: "Data processing / invoer",
    src: "./avatars_small/Jim.png",
    linkedIn: "https://www.linkedin.com/company/pink-raspberry",
  },
  {
    name: "Ties",
    position: "Testen / invoer",
    src: "./avatars_small/Ties.png",
    linkedIn: "https://www.linkedin.com/company/pink-raspberry",
  },
  {
    name: "Dion",
    position: "Testen / invoer",
    src: "./avatars_small/Dion.png",
    linkedIn: "https://www.linkedin.com/company/pink-raspberry",
  },
  {
    name: "Matteo",
    position: "",
    src: "./avatars_small/Matteo.png",
    linkedIn: "https://www.linkedin.com/company/pink-raspberry",
  },
  {
    name: "Ruben",
    position: "",
    src: "./avatars_small/Ruben.png",
    linkedIn: "https://www.linkedin.com/company/pink-raspberry",
  },
];
