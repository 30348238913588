import React, { useEffect } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import ResponsiveAppBar from "../../components/AppBar/AppBar";
import Circle from "../../components/constants/Circle/Circle";
// import Circle from "../../components/constants/Circle/Circle";
import DotsBackground from "../../components/constants/Dots/DotsBackground";
import Footer from "../../components/Footer/Footer";
import PageBackground from "../../components/PageBackground/PageBackground";
import Title from "../../components/typography/Title/Title";
import { circles } from "../../constants/circles";
import { MOBILESX, WEBSX } from "../../sx/common";
import {
  AboutWrapper,
  DescriptionWrapper,
  AccordionWrapper,
  ModalWrapper,
} from "./AboutStyle";

const style = {
  pt: 2,
  px: 4,
  pb: 3,
};

const svgString = `
  <?xml version="1.0" encoding="UTF-8"?>
  <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
  <svg class='leaves' xmlns="http://www.w3.org/2000/svg" version="1.1" width="582px" height="425px" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g><path style="opacity:0.999" fill="#d4d58e" d="M 319.5,-0.5 C 320.5,-0.5 321.5,-0.5 322.5,-0.5C 370.459,28.033 403.793,68.6997 422.5,121.5C 433.294,154.093 439.294,187.593 440.5,222C 439.723,232.254 438.556,242.42 437,252.5C 446.47,260.732 450.637,271.065 449.5,283.5C 432.489,293.007 415.155,302.007 397.5,310.5C 395.981,302.783 392.814,295.783 388,289.5C 372.089,272.917 353.923,259.417 333.5,249C 325.71,245.792 317.71,243.292 309.5,241.5C 310.257,222.885 314.757,205.218 323,188.5C 327.271,180.284 331.771,172.284 336.5,164.5C 346.368,148.402 353.201,131.069 357,112.5C 358.27,99.638 357.27,86.9714 354,74.5C 351.394,66.0143 348.394,57.6809 345,49.5C 336.745,32.6551 328.245,15.9885 319.5,-0.5 Z"/></g>
    <g><path style="opacity:0.999" fill="#c5c891" d="M 449.5,283.5 C 450.637,271.065 446.47,260.732 437,252.5C 438.556,242.42 439.723,232.254 440.5,222C 439.294,187.593 433.294,154.093 422.5,121.5C 441.591,94.9099 464.591,72.0765 491.5,53C 505.837,44.666 520.837,37.666 536.5,32C 549.589,26.6362 562.923,22.3028 576.5,19C 559.439,32.3885 543.272,46.8885 528,62.5C 509.05,84.604 501.05,110.271 504,139.5C 505.508,147.864 507.174,156.198 509,164.5C 509.667,191.167 509.667,217.833 509,244.5C 500.146,254.012 489.646,261.178 477.5,266C 468.307,272.095 458.973,277.928 449.5,283.5Z"/></g>
    <g><path style="opacity:0.999" fill="#c5c994" d="M 336.5,164.5 C 331.771,172.284 327.271,180.284 323,188.5C 314.757,205.218 310.257,222.885 309.5,241.5C 288.433,237.276 267.1,235.109 245.5,235C 244.956,234.283 244.289,233.783 243.5,233.5C 239.763,224.121 236.93,214.454 235,204.5C 233.848,185.15 232.514,165.817 231,146.5C 229.855,139.732 227.522,133.399 224,127.5C 212.262,107.095 196.429,90.5953 176.5,78C 165.377,72.1041 154.044,66.7707 142.5,62C 190.13,63.2112 233.797,76.8778 273.5,103C 296.37,121.536 316.87,142.37 335,165.5C 335.383,164.944 335.883,164.611 336.5,164.5Z"/></g>
    <g><path style="opacity:0.999" fill="#bfc393" d="M 243.5,233.5 C 244.289,233.783 244.956,234.283 245.5,235C 267.1,235.109 288.433,237.276 309.5,241.5C 317.71,243.292 325.71,245.792 333.5,249C 353.923,259.417 372.089,272.917 388,289.5C 392.814,295.783 395.981,302.783 397.5,310.5C 382.393,318.891 367.726,328.057 353.5,338C 344.191,346.977 335.191,356.31 326.5,366C 316.636,372.345 305.969,376.845 294.5,379.5C 288.031,363.859 277.697,351.359 263.5,342C 245.498,330.499 226.498,320.832 206.5,313C 192.501,307.984 178.501,304.484 164.5,302.5C 166.199,302.66 167.866,302.494 169.5,302C 148.87,275.683 131.037,247.516 116,217.5C 104.785,188.445 100.951,158.445 104.5,127.5C 119.555,170.727 146.888,203.894 186.5,227C 191.866,229.849 197.532,231.849 203.5,233C 215.821,234.038 228.154,234.871 240.5,235.5C 242.03,235.36 243.03,234.693 243.5,233.5Z"/></g>
    <g><path style="opacity:0.998" fill="#c5c890" d="M 164.5,302.5 C 178.501,304.484 192.501,307.984 206.5,313C 226.498,320.832 245.498,330.499 263.5,342C 277.697,351.359 288.031,363.859 294.5,379.5C 291.081,380.374 287.748,381.54 284.5,383C 265.451,395.684 244.784,404.684 222.5,410C 217.495,412.17 212.495,414.336 207.5,416.5C 194.708,415.318 182.041,413.485 169.5,411C 155.167,396.667 140.833,382.333 126.5,368C 111.249,355.023 93.5821,347.689 73.5,346C 50.4556,345.088 27.4556,345.588 4.5,347.5C 3.83333,347.167 3.16667,346.833 2.5,346.5C 21.9555,329.77 44.2888,318.27 69.5,312C 100.66,303.931 132.327,300.764 164.5,302.5Z"/></g>
  </svg>
`;

function About() {
  const [isPopped] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [details, setDetails] = React.useState(null);
  const handleOpen = (details) => {
    if(details?.text) {
      setOpen(true);
      setDetails(details);
    }
  };
  const handleClose = () => {
    setOpen(false);
    setDetails(null);
  };

  useEffect(() => {}, [details, isPopped])

  return (
    <PageBackground
      color="#F4EBEC"
      background={{
        image: "./side-logo.png",
        mobileOnly: true,
        position: "top right",
      }}
      component={
        <AboutWrapper isOpen={isPopped}>
          <Box sx={{ display: WEBSX }}>
            <DotsBackground />
          </Box>
          <ResponsiveAppBar isLight={true} />
          <Grid style={{ minHeight: '84vh' }} container spacing={2}>
            <Grid item xs={12} md={5} className="details-wrapper">
              {!isPopped && (
                <div className="details">
                  <Title className="title" text="Over ons" />
                  <br/>
                  <DescriptionWrapper>
                    Pink Raspberry ondersteunt bij alle stappen in het uitgeefproces. Denk hierbij aan bureauredactie, invoeren en testen van content in verschillende CMS'en en het regelen van tekst- en beeldrechten. Daarnaast bieden we administratieve ondersteuning en bouwen we apps, websites en platforms.<br/><br/>
                  </DescriptionWrapper>
                  <DescriptionWrapper>
                    Door onze uiteenlopende achtergronden hebben wij veel kennis, ervaring en creativiteit in huis: van design tot onderwijskunde en van bedrijfskunde tot communicatie. Doordat wij zo breed georiënteerd zijn, bieden wij veel verschillende diensten aan in de educatieve sector, IT- en reisbranche. Bekijk hier al onze <a href='/skills'>skills</a>.<br/><br/>
                  </DescriptionWrapper>
                  {/* <DescriptionWrapper>
                    Pink Raspberry is volop in beweging. Onze manier van werken speelt hierbij een belangrijke rol. De
                    nieuwe digitale wereld waarin traditionele grenzen steeds meer vervagen vraagt om een andere en
                    geoptimaliseerde benadering. Wij benutten deze kansen om onze klanten zo goed mogelijk te
                    bedienen.
                  </DescriptionWrapper>
                  <br/>
                  <DescriptionWrapper>
                    We werken met ‘the best (wo)man for the job’, en dit is niet altijd ‘the best one on paper’.
                    We hebben een goede balans en we ontwikkelen continu. Zowel het team als de klanten zijn
                    hierbij gebaat.
                  </DescriptionWrapper> */}
                </div>
              )}
            </Grid>
            {circles
              .filter((c) => !!c.text)
              .map((a, i) => (
                <Box sx={{ display: MOBILESX }}>
                  <Grid xs={12}>
                    <AccordionWrapper>
                      <Accordion className="accordions">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          key={i}
                        >
                          <Typography>{a.text}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {a.description.map((d) => (
                            <div style={d.style}>
                              <Typography>{d.content}</Typography>
                              <br />
                            </div>
                          ))}
                        </AccordionDetails>
                      </Accordion>
                    </AccordionWrapper>
                  </Grid>
                </Box>
              ))}
            <Box sx={{ display: WEBSX }}>
              <Grid item xs={12} md={7}>
                <ModalWrapper
                  open={open}
                  color={details?.fill}
                  onClose={handleClose}
                  aria-labelledby="parent-modal-title"
                  aria-describedby="parent-modal-description"
                  className="modal" 
                >
                  <Box sx={{ ...style }}>
                    <h2 id="parent-modal-title">{details?.text}</h2>
                    <p id="parent-modal-description">
                      {(details?.description ?? []).map((d) => (
                        <div style={d.style} className={d?.className || ''}>
                          <Typography>{d.content}</Typography>
                          <br />
                        </div>
                      ))}
                    </p>
                    <a href='/contact' style={{ textDecoration: 'none', padding: 10, backgroundColor: '#fff', color: '#99004d', borderRadius: 15, fontWeight: 600 }}>Contact</a>
                  </Box>
                </ModalWrapper>
                <div className="group-raspberry">
                  <div dangerouslySetInnerHTML={{ __html: svgString }} />
                  <svg class={`circles ${isPopped && "animate"}`}>
                    {circles.map((c) => (
                      <Circle
                        r={c?.r ?? "7.063em"}
                        onClick={() => handleOpen(c)}
                        className={`circle ${!isPopped && "animate"}`}
                        cx={c.cx}
                        cy={c.cy}
                        text={c?.text}
                        textParts={c?.textParts}
                        fontFamily={isPopped && 'Avenir'}
                        fontSize={isPopped && '19px'}
                        fill={c.fill}
                      />
                    ))}
                  </svg>
                </div>
              </Grid>
            </Box>
          </Grid>
          <Footer color="#00999D" />
        </AboutWrapper>
      }
    />
  );
}

export default About;
