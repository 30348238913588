import * as React from "react";
import { useNavigate } from "react-router-dom";
import { teamHomePage as team } from "../../constants/team";
import { NetworkWrapper } from "./styles/NetworkStyle";

interface NetworkTypes {
  images?: any;
}

const circles = [
  {
    cx: "44",
    cy: "144",
  },
  {
    cx: "1140.75",
    cy: "572.299",
    r: "43.8254",
  },
  {
    cx: "131",
    cy: "374",
  },
  {
    cx: "204",
    cy: "749",
  },
  {
    cx: "521",
    cy: "132",
  },
  {
    cx: "269",
    cy: "235",
  },
  {
    cx: "682",
    cy: "44",
  },
  {
    cx: "801",
    cy: "404",
  },
  {
    cx: "949",
    cy: "100",
  },
  {
    cx: "661",
    cy: "275",
  },
  {
    cx: "992",
    cy: "235",
  },
  {
    cx: "595",
    cy: "557",
  },
  {
    cx: "1140",
    cy: "742",
  },
  {
    cx: "999",
    cy: "696",
  },
  {
    cx: "354",
    cy: "460",
  },
];
function Network({ images }: NetworkTypes) {
  const history = useNavigate();
  return (
    <NetworkWrapper>
      <svg
        width="130vh"
        height="85vh"
        viewBox="0 0 1185 794"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M47.6709 145.859L156.303 192.318L264.936 238.777"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-dasharray="4 4"
        />
        <path
          d="M265.735 240.89L515.617 131.85L687.354 40.9843L948.14 104.591L996.299 240.89L1141.68 582.546V754.284L996.299 697.946L807.297 401.723L660.094 275.419L591.992 567.099L201.22 756.101L130.345 378.098L253.922 255.428"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-dasharray="4 4"
        />

        <path
          d="M660.221 277.292L525.786 133.254L206.548 750.559M137.569 376.061L591.992 563.996M804.258 402.125L1008.66 233.395M937.322 90.7291L668.451 277.292M683.541 46.8319L651.99 289.638"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-dasharray="4 4"
        />
        {/* 1097 =143, 530 = 42 */}
        {circles.map((c, i) => (
          <g onClick={() => history("/team")}>
            <circle cx={c.cx} cy={c.cy} r="43" fill="#D0F8FA" />
            <image
              className="img-circle"
              xlinkHref={team[i] ? team[i].src : "./default-avatar.png"}
              x={Number(c.cx) - 43}
              y={Number(c.cy) - 43}
              height="87"
            />
          </g>
        ))}
      </svg>
    </NetworkWrapper>
  );
}

export default Network;
