import { Box, CardContent, Grid, Typography } from "@mui/material";
import React from "react";
// import Carousel from "react-multi-carousel";
import ResponsiveAppBar from "../../components/AppBar/AppBar";
// import BackgroundLogo from "../../components/constants/BackgroundLogo/BackgroundLogo";
import DotsLeft from "../../components/constants/Dots/DotsLeft";
import Footer from "../../components/Footer/Footer";
import PageBackground from "../../components/PageBackground/PageBackground";
import Title from "../../components/typography/Title/Title";
import { DescriptionWrapper } from "../About/AboutStyle";
import { CardWrapper, TeamWrapper, StackWrapper } from "./TeamStyle";
import "react-multi-carousel/lib/styles.css";
import { CardAvatar } from "../../components/Avatar/AvatarStyle";
import { team } from "../../constants/team";
import { WEBSX } from "../../sx/common";
// const responsive = {
//   superLargeDesktop: {
//     // the naming can be any, depends on you.
//     breakpoint: { max: 4000, min: 3000 },
//     items: 5,
//   },
//   desktop: {
//     breakpoint: { max: 3000, min: 1500 },
//     items: 4,
//   },
//   laptop: {
//     breakpoint: { max: 1500, min: 1155 },
//     items: 3,
//   },
//   tablet: {
//     breakpoint: { max: 1154, min: 780 },
//     items: 2,
//   },
//   mobile: {
//     breakpoint: { max: 780, min: 0 },
//     items: 1,
//   },
// };
function Team() {
  return (
    <PageBackground
      color="#F4EBEC"
      background={{
        image: "./side-logo.png",
        mobileOnly: false,
        position: "top right",
      }}
      component={
        <TeamWrapper>
          <ResponsiveAppBar isLight={true} />
          <Box sx={{ display: WEBSX }}>
            <DotsLeft />
          </Box>
          <Grid container columnGap={5} className="header">
            <Grid md={12} lg={5} item className="title">
              <Title text="Ons team" />
            </Grid>
            <Grid md={12} lg={7} item className="description">
              <DescriptionWrapper style={{ color: '#000', fontSize: 16, maxWidth: 550 }}>
                Maak kennis met het team: het kloppend hart van Pink Raspberry.
                Een mix van creativiteit, vakmanschap en allrounders. Wij houden
                van ons werk, en dat zie je terug!
              </DescriptionWrapper>
            </Grid>
          </Grid>
          <Grid container className="cards-wrapper">
            <Grid item xs={2} />
            <Grid item container xs={8} style={{ paddingBottom: 30 }}>
              {team
                .filter((t) => t.description !== "")
                .map((t) => (
                  <Grid style={{ paddingLeft: '1%', paddingRight: '1%' }} item md={6} lg={4} xl={3} sm={6} xs={12}>
                    <CardWrapper sx={{ maxWidth: 368 }}>
                      <CardContent>
                        <StackWrapper
                          direction="row"
                          alignItems="flex-end"
                          className="avatar"
                        >
                          <CardAvatar
                            src={t?.src ?? "./default-avatar-xl.png"}
                            alt={t.name}
                            sx={{ width: 150, height: 150 }}
                          />
                          <CardAvatar
                            src="./linkedin.png"
                            alt="Remy Sharp"
                            className="linked-in"
                            onClick={() =>
                              window.open(
                                t?.linkedIn || "https://www.linkedin.com/feed/"
                              )
                            }
                          />
                        </StackWrapper>
                        <Typography
                          gutterBottom
                          variant="h6"
                          component="div"
                          className="name"
                        >
                          {t?.name}
                        </Typography>
                        <Typography className="position">
                          {t?.position}
                        </Typography>
                        {t?.email && (
                          <Typography className="email">
                          {t?.email}
                          </Typography>
                        )}
                        <Typography className="details">
                          {t?.description}
                        </Typography>
                      </CardContent>
                    </CardWrapper>
                  </Grid>
                ))}
            </Grid>
            <Grid item xs={2} />
          </Grid>
          {/* <BackgroundLogo /> */}
          <br />
          <Footer color="#00999D" />
        </TeamWrapper>
      }
    />
  );
}

export default Team;
