import styled from "styled-components";

export const AppBarWrapper = styled.div`
  z-index: 777 !important;
  position: relative;

  @media (max-width: 900px) {
    position: sticky;
  }

  header {
    background: transparent;
    box-shadow: none;
  }

  .MuiDrawer-paperAnchorRight {
    width: 100% !important;
  }

  .menu-appbar {
    position: absolute;
    right: 0;
  }

  .buttons-wrapper {
    place-content: center;
    margin-top: -20px;

    .appbar-button {
      font-family: "Avenir Light";
      text-transform: lowercase;
    }

    .appbar-button:hover:after {
      width: 60%;
    }

    .active {
      font-family: "Avenir";
      font-size: 15px;
    }

    .active:after {
      content: "";
      display: block;
      margin: auto;
      width: 60% !important;
      height: 2px !important;
      background: #b71667 !important;
      -webkit-transition: width 0.3s ease-out !important;
      -o-transition: width 0.3s ease-out !important;
      transition: width 0.3s ease-out !important;
    }

    .appbar-button:after {
      content: "";
      display: block;
      margin: auto;
      width: 0;
      height: 2px;
      background: #b71667;
      -webkit-transition: width 0.3s ease-out;
      -o-transition: width 0.3s ease-out;
      transition: width 0.3s ease-out;
    }

    .light {
      color: #000000;
    }
  }
`;

export const LogoWrapper = styled.div`
  z-index: 777 !important;
  &:hover {
    cursor: pointer;
  }
  .MuiBox-root {
    display: flex;
    align-items: flex-end;
    border-radius: 150px;
    justify-content: center;

    img {
      padding-bottom: 45px;
    }
    margin-top: -150px;
  }

  @media (max-width: 900px) {
    .MuiBox-root {
      display: flex;
      align-items: flex-end;
      border-radius: 150px;
      justify-content: center;
      margin-left: -15px;

      img {
        padding-bottom: 35px;
      }
      margin-top: -120px;
    }
  }
`;
