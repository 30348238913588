import { keyframes } from "styled-components";
import styled from "styled-components";
import { Modal } from "@mui/material";

export const animate = keyframes`
 100% { transform: translate(200px, -230px); }
`;

export const animate2 = keyframes`
 100% { transform: translate(-120px, -90px); }
`;

export const animate3 = keyframes`
 100% { transform: translate(-210px, 60px); }
`;

export const lastCircle = keyframes`
 100% { opacity: 0 }
`;

export const animateBlack = keyframes`
100% { transform: translate(200px, -170px); }
`;

export const animationClick = keyframes`
100% { transform: translate(327px, 706px); }
`;

export const animate4 = keyframes`
100% { transform: translate(-710px, 10px); }
`;

export const animate5 = keyframes`
100% { transform: translate(170px, 10%); }
`;

export const animate6 = keyframes`
100% { transform: translate(-800px, 50px); }
`;

export const animate7 = keyframes`
100% { transform: translate(-600px, -230px); }
`;

export const animate8 = keyframes`
100% { transform: translate(-10px, 10px); }
`;

export const animate9 = keyframes`
100% { transform: translate(-310px, -200px); }
`;

export const animate10 = keyframes`
100% { transform: translate(-590px, 150px); }
`;

export const animate11 = keyframes`
100% { transform: translate(10px, -200px); }
`;

export const animate12 = keyframes`
100% { transform: translate(-90px, 15%); }
`;

export const animate14 = keyframes`
100% { transform: translate(-1150px, -10%); }
`;

export const AboutWrapper = styled.div<{ isOpen?: boolean }>`
  min-height: 91.1vh;
  .animate {
    .pop-circle {
      &:hover {
        r: 7.5em !important;
      }
    }
    g:first-child {
      animation: ${animate} 3s;
      animation-fill-mode: both;
    }
    g:nth-child(2) {
      animation: ${animate2} 3s;
      animation-fill-mode: both;
    }
    g:nth-child(3) {
      animation: ${animate3} 3s;
      animation-fill-mode: both;
    }
    g:nth-child(4) {
      animation: ${animate4} 2s;
      animation-fill-mode: both;
    }
    g:nth-child(5) {
      animation: ${animate5} 2s;
      animation-fill-mode: both;
    }
    g:nth-child(6) {
      animation: ${animate6} 2s;
      animation-fill-mode: both;
    }
    g:nth-child(7) {
      animation: ${animate7} 2s;
      animation-fill-mode: both;
    }
    g:nth-child(8) {
      animation: ${animate8} 2s;
      animation-fill-mode: both;
    }
    g:nth-child(9) {
      animation: ${animate9} 2s;
      animation-fill-mode: both;
    }
    g:nth-child(10) {
      animation: ${animate10} 2s;
      animation-fill-mode: both;
    }
    g:nth-child(11) {
      animation: ${animate11} 2s;
      animation-fill-mode: both;
    }
    g:nth-child(12) {
      animation: ${animate12} 2s;
      animation-fill-mode: both;
    }
    g:nth-child(13) {
      animation: ${animate14} 2s;
      animation-fill-mode: both;
    }
    g:nth-child(14) {
      animation: ${lastCircle} 2s;
      animation-fill-mode: both;
    }
    g:nth-child(15) {
      animation: ${animateBlack} 2s;
      animation-fill-mode: both;
      circle {
        r: 7.063em !important;
      }
      path {
        animation: ${animationClick} 1s;
        animation-fill-mode: both;
      }
    }
  }
  .circles {
    width: 23%;
    height: 45%;
    outline: 0;
    transform: translate(10%,-40%) scale(0.85);
    position: absolute;
    top: 35%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    overflow: visible;

    g {
      -webkit-filter: drop-shadow(8px 8px 5px rgba(0, 0, 0, 0.15));
      filter: drop-shadow(8px 8px 5px rgba(0, 0, 0, 0.15));
      cursor: pointer;
      .circle {
        &:hover {
          r: 7.5em;
        }
      }
      .pop-circle {
        &:hover {
          r: 6.8em;
        }
      }
    }
  }
  .details-wrapper {
    display: flex;
    place-content: end;
    .details {
      transition: opacity 5s linear;
      transform: translate(0);
      opacity: 1;
      height: 90vh;
      width: 491.01px;
      padding-left: 50px;
      display: flex;
      place-content: center;
      flex-direction: column;
      margin-top: -70px;
    }
    @media (max-width: 980px) {
      .details {
        padding-top: 80px;
        padding-right: 50px;
        transition: opacity 5s linear;
        transform: translate(0);
        opacity: 1;
        height: fit-content;
        width: 100%;
        padding-left: 50px;
        display: flex;
        place-content: flex-start;
        flex-direction: column;
        margin-top: -10px;

        div:first-child {
          font-family: "Avenir" !important;
          font-style: normal;
          font-weight: 800;
          font-size: 40px;
          line-height: 55px;
        }

        p {
          font-style: normal;
          // font-weight: 400;
          font-size: 16px;
          line-height: 26px;
          color: #7e7e7e;
        }
      }
    }
    .hidden {
      display: none;
      opacity: 0;
    }

    @media (max-height: 600px) {
      .details {
        div:first-child {
          padding-top: 12%;
        }
      }
    }
  }

  @media (max-width: 900px) {
    .accordions {
      background: #f1e5e6;
      border: 1px px solid #ecdedf;
      border-radius: 10px !important;
      box-shadow: 0px 1px 0px 0px #d6d6d6, 0px 1px 0px 0px #d6d6d6,
        0px 1px 0px 0px #d6d6d6;

      min-height: 49px;
      .MuiAccordionSummary-contentGutters {
        margin: 10px 0;
      }
      p {
        font-family: "Avenir" !important;
      }
      .MuiAccordion-region {
        p {
          font-family: "Avenir Light" !important;
          font-size: 16px;
        }
      }
    }
  }
  // @media (max-height: 755px) {
  //   .MuiGrid-container {
  //     padding-top: 50px;
  //     height: inherit;
  //   }
  //   .leaves {
  //     margin-top: -40%;
  //   }
  //   .circles {
  //     margin-top: -5%;
  //   }
  // }
  // @media (max-height: 620px) {
  //   .MuiGrid-container {
  //     padding-top: 70px;
  //   }
  // }
  // @media (max-height: 650px) {
  //   .MuiGrid-container {
  //     padding-top: 50px;

  //     p {
  //       font-size: 16px;
  //     }
  //   }
  //   .circles {
  //     transform: translate(10%, -40%) scale(0.6);
  //   }
  // }
  // @media (max-width: 1779px) {
  //   .leaves {
  //     transform: translate(-3%, -5%) scale(1.0);
  //   }
  //   .circles {
  //     transform: translate(10%, -40%) scale(0.8);
  //   }
  // }
  // @media (max-width: 1240px) {
  //   .leaves {
  //     transform: translate(0%, -5%) scale(0.9);
  //   }
  //   .circles {
  //     transform: translate(22%, -40%) scale(0.7);
  //   }
  // }
  // @media (max-width: 1160px) {
  //   .leaves {
  //     transform: translate(-3%, -3%) scale(0.85);
  //   }
  //   .circles {
  //     transform: translate(22%, -40%) scale(0.65);
  //   }
  // }
  // @media (max-width: 1090px) {
  //   .leaves {
  //     transform: translate(-5%, -5%) scale(0.70);
  //   }
  //   .circles {
  //     transform: translate(22%, -40%) scale(0.50);
  //   }
  // }
  // @media (min-width: 1780px) {
  //   .leaves {
  //     transform: translate(15%, -6%) scale(1.05);
  //   }
  //   .circles {
  //     transform: translate(30%, -40%) scale(0.8);
  //   }
  // }
  // @media (max-height: 570px) {
  //   .MuiGrid-container {
  //     padding-top: 50px;

  //     p {
  //       font-size: 15px;
  //     }
  //   }
  //   .leaves {
  //     transform: translate(-3%, -22%) scale(0.6);
  //   }
  //   .circles {
  //     transform: translate(10%, -40%) scale(0.5);
  //   }
  // }
  // @media (max-width: 980px) {
  //   .circles {
  //     display: none;
  //   }
  //   .leaves {
  //     display: none;
  //   }
  // }
  // @media (max-height: 830px) {
  //   .leaves {
  //     transform: translate(5%, -10%) scale(0.85);
  //   }
  //   .circles {
  //     transform: translate(22%, -40%) scale(0.65);
  //   }
  // }
  // @media (max-height: 830px) {
  //   .leaves {
  //     transform: translate(5%, -10%) scale(0.85);
  //   }
  //   .circles {
  //     transform: translate(22%, -40%) scale(0.65);
  //   }
  // }
  @media (max-width: 1285px) {
    .group-raspberry {
      transform: translate(7%, 0%) scale(0.85);
    }
  }
  @media (max-width: 1150px) {
    .group-raspberry {
      transform: translate(1%, 0%) scale(0.80);
    }
  }
  @media (max-height: 880px) {
    .group-raspberry {
      transform: translate(7%, -10%) scale(0.85);
    }
  }
  @media (max-width: 1040px) {
    .group-raspberry {
      transform: translate(0%, -10%) scale(0.80);
    }
  }
  @media (max-height: 750px) {
    .group-raspberry {
      transform: translate(7%, -10%) scale(0.80);
    }
  }
  @media (max-width: 945px) {
    .group-raspberry {
      transform: translate(0%, -10%) scale(0.75);
    }
  }
  @media (max-height: 700px) {
    .group-raspberry {
      transform: translate(7%, -10%) scale(0.75);
    }
  }
  @media (max-height: 600px) {
    .group-raspberry {
      transform: translate(7%, -25%) scale(0.70);
    }
  }
`;

export const DescriptionWrapper = styled.p`
  font-family: "Avenir Light";
  font-size: 18px;
  line-height: 26px;
  text-align: left;
  margin: 0;
`;

export const AccordionWrapper = styled.p`
  margin-top: -5px;
  padding: 0 30px 0 40px;
`;

export const ModalWrapper = styled(Modal)<{ color?: any }>`
  .MuiBox-root {
    border-radius: 400px;
    outline: 0;
    padding: 100px;
    text-align: center;
    color: #fff;
    width: 400px;
    height: 400px;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    background: ${(p) => p.color ?? '#fff'} !important;
  }
`;
