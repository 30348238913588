import styled from "styled-components";

export const HomeWrapper = styled.div`
  background-image: url("./map.png");
  background-repeat: no-repeat;
  background-position: right;
  min-height: 91.1vh;

  .network-wrapper {
    transform: translate(-5%, 0) scale(0.9);
  }
  .map {
    position: fixed;
    bottom: 0;
    right: 0;
    max-height: 800px;
  }
  @media (max-width: 1300px) {
    .network-wrapper {
      transform: scale(0.8);
    }
  }
  @media (max-width: 1280px) {
    .network-wrapper {
      transform: translate(5%, -5%) scale(0.8);
    }
  }
  @media (max-width: 1200px) {
    .network-wrapper {
      transform: scale(0.7);
    }
  }
  @media (max-width: 1100px) {
    .network-wrapper {
      transform: scale(0.65);
    }
  }
  @media (max-width: 1000px) {
    .network-wrapper {
      transform: scale(0.5);
    }
  }
  @media (max-width: 800px) {
    .network-wrapper {
      transform: scale(0.6);

      svg {
        margin-left: -150px;
        margin-top: -200px;
      }
    }
    .map {
      max-height: 400px;
    }
  }
  @media (max-width: 768px) {
    .network-wrapper {
      transform: scale(0.48);

      svg {
        margin-left: -250px;
        margin-top: -300px;
      }
    }
    .map {
      max-height: 400px;
    }
  }
  @media (max-width: 600px) {
    .network-wrapper {
      transform: scale(0.45);

      svg {
        margin-left: -270px;
        margin-top: -330px;
      }
    }
    .map {
      max-height: 400px;
    }
  }
  @media (max-width: 500px) {
    .network-wrapper {
      transform: scale(0.35);

      svg {
        margin-left: -390px;
        margin-top: -390px;
        margin-bottom: -80px;
      }
    }
    .map {
      max-height: 400px;
    }
  }
  @media (max-width: 450px) {
    .network-wrapper {
      transform: scale(0.35);

      svg {
        margin-left: -355px;
        margin-top: -390px;
        margin-bottom: -80px;
      }
    }
    .map {
      max-height: 400px;
    }
  }
`;

export const TagLine = styled.h1`
  display: flex;
  padding-left: 4.5em;
  text-align: left;
  align-items: flex-end;
  height: 72vh;

  .main-heading {
    display: flex;
    flex-direction: column-reverse;
    h1 {
      font-size: 110px;
      text-align: left;
      line-height: 1 !important;
      margin-bottom: -0;
      z-index: 2 !important;
      span {
        font-size: 80px;
      }
    }

    img {
      z-index: 2 !important;
      margin-left: -4em;
      margin-top: -1.5em;
      max-width: 653px;
      max-height: 68.77px;
    }
  }

  p {
    font-size: 19px;
    margin-top: 0;
    width: 455px;
    font-family: "Avenir Light";
  }

  @media (max-width: 1000px) {
    height: 80%;
    padding-left: 0px;
    margin-left: 50px;
    width: fit-content;
    .main-heading {
      img {
        margin-left: -1em;
        margin-top: -1.5em;
        max-width: 400px;
        max-height: 68.77px;
        z-index: 1 !important;
      }
      h1 {
        font-size: 105px;
        margin-top: 10px;
        z-index: 2 !important;
        line-height: 0.9 !important;
      }
    }
    p {
      width: 100%;
    }
  }
  @media (max-width: 900px) {
    height: auto;
    padding-left: 0px;
    margin-left: 10px;
    width: fit-content;
    .main-heading {
      img {
        display: none;
        z-index: 1 !important;
      }
      h1 {
        font-size: 98px;
        margin-top: 10px;
        line-height: 0.9 !important;
        z-index: 2 !important;
      }
    }
    p {
      width: 100%;
    }
  }
`;
